import { BackTop, Typography } from 'antd';

import { useEmailClick } from 'utils/hooks/useEmailClick';
import { FormContainer } from 'components/common/forms/form-container';
import { Agreement } from './Agreement';

const { Title, Paragraph, Text } = Typography;

export const TermsPage = () => {
  return (
    <FormContainer>
      <BackTop />
      <Typography>
        <Title>Terms and Conditions</Title>
        <Paragraph>
          <Text> This site is owned and operated by: </Text> <br /> <br />
          <Text strong>First Media Ltd</Text> <br />
          <Text>13/1 Line Wall Road</Text> <br />
          <Text>GX11 1AA Gibraltar</Text> <br />
          <Text>Gibraltar</Text> <br /> <br />
          <Text>
            For any inquiries, you can contact us directly through email at{' '}
            <a target="_blank" href="mailto:contact@mondiad.com" onClick={useEmailClick()} rel="noreferrer">
              contact@mondiad.com
            </a>
            .
          </Text>
        </Paragraph>
        <Paragraph>
          By using our services, you are agreeing to our Terms and Conditions, so please read them carefully. <br />
          <a href="#advertiser">Advertiser Agreement</a> | <a href="#publisher">Publisher Agreement</a>
        </Paragraph>
      </Typography>

      <Agreement />
    </FormContainer>
  );
};
