import { useEffect, useState } from 'react';
import queryString from 'query-string';

import { redirect } from 'utils/redirect';
import { getRedirectUrlRequest } from 'modules/api-requests/authorization';

import { storage } from './storage';

function saveRefreshToken(refreshToken: string) {
  storage.saveRefreshToken(refreshToken);
}

export function useUserRedirect() {
  const [redirecting, setRedirecting] = useState(false);

  async function tryRedirectUser() {
    const query = queryString.parse(window.location.search);
    const { logoutFrom } = query;

    if (logoutFrom) {
      storage.clearRefreshToken();
      return;
    }

    const refreshToken = storage.getRefreshToken();
    if (!refreshToken) return;

    setRedirecting(true);
    try {
      const {
        data: {
          data: { redirectUrl },
        },
      } = await getRedirectUrlRequest({ token: refreshToken });

      redirectUrl && redirect(redirectUrl);
    } catch {
      storage.clearRefreshToken();
    } finally {
      setRedirecting(false);
    }
  }

  useEffect(() => {
    tryRedirectUser();
  }, []);

  return { saveRefreshToken, redirecting };
}
