import { LanguageKeys } from 'modules/localization/entities';

export type TwoFactorType = 'Email' | 'App';

export type LoginRequestData = {
  email: string;
  password: string;
  remember: boolean;
  captchaCode: string;
  deviceFingerprint: string;
  totpCode?: string;
  language?: LanguageKeys;
};

export type ResendActivationEmailRequestData = Pick<LoginRequestData, 'email' | 'password' | 'captchaCode'> & {
  language?: LanguageKeys;
};

export type AuthToken = {
  expired: string;
  refreshToken: string;
  token: string;
  totpEnabled: boolean;
  twoFactorTimeSeconds: number;
  twoFactorAuthenticationType?: TwoFactorType;
  authCode: string;
  redirectUrl: string;
  email?: string;
};

export const USER_WAS_NOT_ACTIVATED_CODE = 423;

export type Country = {
  id: string;
  name: string;
};

export type ConfirmEmailRequestData = {
  token: string;
  language?: LanguageKeys;
};
