import { useState, useCallback } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Layout, Form, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { AuthToken, USER_WAS_NOT_ACTIVATED_CODE, TwoFactorType } from 'modules/api-requests/authorization/entities';
import { loginRequest, resendConfirmEmailRequest } from 'modules/api-requests/authorization';
import { FormContainer } from 'components/common/forms/form-container';
import { getBadgeText } from 'utils/env';
import { Footer } from 'components/layout/footer';
import { Link } from 'components/common/link';
import { PageRoute } from 'components/routing/utils';
import { MondiadLogo } from 'components/common/MondiadLogo';
import { BoldTitle } from 'components/common/BoldTitle';
import { redirect } from 'utils/redirect';
import i18n from 'modules/localization';

import { LoginForm, LoginFormValues } from './LoginForm';
import { LoginAlert, LoginAlertError, errorsInitState } from './LoginAlert';
import { TwoFAFormValues, TwoFAForm } from './TwoFAForm';
import { NewUserModal } from './NewUserModal';
import { useTwoFATimer } from './useTwoFATimer';
import { useUserRedirect } from './useUserRedirect';
import { useFingerPrint } from './useFingerPrint';

import style from './style.module.scss';
import { LanguageKeys } from 'modules/localization/entities';

const errorEmpty2FA: LoginAlertError = {
  errorText: i18n.t('login:errorEmpty2FA'),
  errorCode: 400,
};
export const LoginPage = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoginAlert, setErrorLoginAlert] = useState<LoginAlertError>(errorsInitState);
  const [totpType, setTotpType] = useState<TwoFactorType>();
  const [loginFormValues, setLoginFormValues] = useState<LoginFormValues>();
  const [emailWasSent, setEmailWasSent] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const { t, i18n } = useTranslation('login');
  const { startTwoFATimer, resetTwoFATimer } = useTwoFATimer(setTotpType);
  const { saveRefreshToken, redirecting } = useUserRedirect();
  const { deviceFingerprint } = useFingerPrint();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const language = i18n.language as LanguageKeys;

  const loading = redirecting || isLoading || !executeRecaptcha;

  const goToApplication = useCallback(
    ({ redirectUrl, authCode, refreshToken }: AuthToken, { remember, email }: LoginFormValues) => {
      saveRefreshToken(refreshToken);
      redirect(`${redirectUrl}/auto-login`, {
        authCode,
        email,
        backLink: location.href,
        remember: String(remember),
      });
    },
    [saveRefreshToken]
  );

  const handleValuesChange = useCallback(() => {
    setErrorLoginAlert(errorsInitState);
  }, []);

  const serverErrorHandler = useCallback((error: unknown) => {
    if (axios.isAxiosError(error)) {
      const errorCode = error.response?.data?.status || 0;

      if (errorCode === USER_WAS_NOT_ACTIVATED_CODE) {
        const emailWasSent = error.response?.data?.data?.emailWasSent || false;
        setEmailWasSent(emailWasSent);
        setShowNewUserModal(true);
      } else {
        setErrorLoginAlert({
          errorText: error.response?.data?.message || '',
          rateLimitRetryAfterSeconds: error.response?.data?.rateLimitRetryAfterSeconds,
          errorCode,
        });
      }
    }
  }, []);

  const handleSubmitLoginForm = useCallback(
    async (loginFormValues: LoginFormValues) => {
      if (!executeRecaptcha) return;
      setIsLoading(true);
      const captchaCode = await executeRecaptcha();

      try {
        const {
          data: { data: response },
        } = await loginRequest({ ...loginFormValues, deviceFingerprint, captchaCode, language });
        if (response.twoFactorAuthenticationType) {
          setLoginFormValues(loginFormValues);
          setTotpType(response.twoFactorAuthenticationType);
          startTwoFATimer();
          setIsLoading(false);
          return;
        }
        goToApplication(response, loginFormValues);
      } catch (err) {
        setIsLoading(false);
        serverErrorHandler(err);
      }
    },
    [deviceFingerprint, executeRecaptcha, goToApplication, language, serverErrorHandler, startTwoFATimer]
  );

  const handleSubmitTwoFaForm = useCallback(
    async ({ totpCode }: TwoFAFormValues) => {
      const totpCodeTrim = totpCode.trim();
      if (!totpCodeTrim) {
        setErrorLoginAlert(errorEmpty2FA);
        return;
      }
      if (!executeRecaptcha || !loginFormValues) return;
      setIsLoading(true);
      const captchaCode = await executeRecaptcha();
      const { email, password, remember } = loginFormValues;

      try {
        const {
          data: { data: response },
        } = await loginRequest({
          email,
          password,
          totpCode: totpCodeTrim,
          remember,
          deviceFingerprint,
          captchaCode,
          language,
        });
        goToApplication(response, loginFormValues);
      } catch (err) {
        serverErrorHandler(err);
        setIsLoading(false);
      }
    },
    [deviceFingerprint, executeRecaptcha, goToApplication, language, loginFormValues, serverErrorHandler]
  );

  const handleBackButtonClick = useCallback(() => {
    setTotpType(undefined);
    setErrorLoginAlert(errorsInitState);
  }, []);

  const closeModal = useCallback(() => {
    setShowNewUserModal(false);
  }, []);

  const resendEmail = useCallback(async () => {
    const { email, password } = form.getFieldsValue();
    if (!executeRecaptcha) return;
    const captchaCode = await executeRecaptcha();

    return resendConfirmEmailRequest({ email, password, captchaCode, language });
  }, [executeRecaptcha, form, language]);

  return (
    <Layout>
      <Layout.Content>
        <FormContainer
          oneColumn
          withMarginTop
          style={{
            maxWidth: '35rem',
          }}
        >
          <NewUserModal
            resendEmail={resendEmail}
            visible={showNewUserModal}
            emailWasSent={emailWasSent}
            closeModal={closeModal}
          />
          <MondiadLogo />
          <Helmet title="Mondiad" />
          <BoldTitle className={style.title}>
            {t('title')}
            {getBadgeText() && <Tag className={style.environmentTag}>{getBadgeText()}</Tag>}
          </BoldTitle>

          {Boolean(errorLoginAlert.errorCode) && (
            <LoginAlert {...errorLoginAlert} setErrorLoginAlert={setErrorLoginAlert} />
          )}

          {totpType ? (
            <TwoFAForm
              onSubmit={handleSubmitTwoFaForm}
              onValuesChange={handleValuesChange}
              onBackButtonClick={handleBackButtonClick}
              totpType={totpType}
              loading={loading}
              loginFormValues={loginFormValues as LoginFormValues}
              resetTwoFATimer={resetTwoFATimer}
              deviceFingerprint={deviceFingerprint}
            />
          ) : (
            <LoginForm
              form={form}
              onSubmit={handleSubmitLoginForm}
              onValuesChange={handleValuesChange}
              initialValues={loginFormValues}
              loading={loading}
            />
          )}

          <Link to={PageRoute.forgotPassword} className={style.forgotPassword}>
            {t('forgotPassword')}?
          </Link>
        </FormContainer>
      </Layout.Content>
      <div className={style.signUpLink}>
        <Link to={PageRoute.registration}>{t('signUp')}</Link>
      </div>
      <Footer />
    </Layout>
  );
};
