import { Typography } from 'antd';

import { Link } from 'components/common/link';
import { PageRoute } from 'components/routing/utils';
import { TERMS_COMPANY_NAME } from 'components/const';
import { useEmailClick } from 'utils/hooks/useEmailClick';

const { Title, Paragraph, Text } = Typography;

const hostName = `${location.protocol}//${location.hostname}`;
const scrollToUp = () => window.scrollTo(0, 0);

export const Agreement = () => {
  const PrivacyLink = (
    <Link to={PageRoute.privacy} onClick={scrollToUp}>
      {hostName}/privacy
    </Link>
  );

  return (
    <Typography>
      <Title level={2}>1. Acceptance of terms</Title>
      <Paragraph>
        <>{TERMS_COMPANY_NAME} </>provides advertising services, defined below, to you subject to the following Terms of
        Service. By accessing and using<> {TERMS_COMPANY_NAME} </>services you accept and agree to be bound by terms and
        provisions of the Terms of Service.
      </Paragraph>

      <Title level={2}>2. Definitions</Title>
      <Paragraph>
        <Text strong>Publisher</Text> is the client of <>{TERMS_COMPANY_NAME}</>, owner or manager of one or more
        websites, that is using <>{TERMS_COMPANY_NAME}</> advertising services to display ads delivered through
        <> {TERMS_COMPANY_NAME}</> ad server to his website visitors in return for a fee.
      </Paragraph>
      <Paragraph>
        <Text strong>Advertiser</Text> is the<> {TERMS_COMPANY_NAME} </>client that will buy ads delivered through
        <> {TERMS_COMPANY_NAME} </>ad server to display owned or 3rd party promotions.
      </Paragraph>
      <Paragraph>
        <Text strong>Visitor</Text> is the person that will visit a website registered to<> {TERMS_COMPANY_NAME} </>by
        the publisher who will see the ad delivered by<> {TERMS_COMPANY_NAME}</>.
      </Paragraph>
      <Paragraph>
        Classic Push, In-page push, Native is the type of ads displayed by<> {TERMS_COMPANY_NAME} </>to the website
        owned or managed by the publisher. The ads are displayed through various methods, with the consent of the
        website owner.
      </Paragraph>
      <Paragraph>
        <Text strong>Referrer</Text> is the existing client of<> {TERMS_COMPANY_NAME} </>that will promote our services
        through a referral program. The referrer will earn a commission for the new clients registered to our services.
      </Paragraph>
      <Paragraph>
        <Text strong>Referee</Text> is the person that becomes a client of<> {TERMS_COMPANY_NAME} </>services when
        referred to<> {TERMS_COMPANY_NAME} </>by a 3rd party existing customer.
      </Paragraph>

      <Title level={2}>
        3. Description of<> {TERMS_COMPANY_NAME} </>services
      </Title>
      <Paragraph>
        <>{TERMS_COMPANY_NAME} </>will act as an automated marketplace between publishers and advertisers. By signing up
        you also agree that the<> {TERMS_COMPANY_NAME} </>services may include certain communications from
        <> {TERMS_COMPANY_NAME} </>such as newsletters, administrative messages, service announcements and that these
        communications are part of<> {TERMS_COMPANY_NAME} </>membership. Unless explicitly stated otherwise, any new
        features that augment or enhance the current<> {TERMS_COMPANY_NAME} </>services shall be subject to the Terms of
        Service. You understand and agree that the<> {TERMS_COMPANY_NAME} </>services is provided "AS-IS" and that
        <> {TERMS_COMPANY_NAME} </>assumes no responsibility for the timeliness, deletion, mis-delivery or failure to
        store any user communications or personalization settings.
      </Paragraph>

      <Title level={2}>4. User registration</Title>

      <Title level={4}>4.1 Registration obligations</Title>
      <Paragraph>
        In consideration of your use of<> {TERMS_COMPANY_NAME} </>services, you agree to provide accurate, true, up to
        date and complete information about yourself as prompted by the<> {TERMS_COMPANY_NAME} </>registration data and
        maintain the data of your account up to date, true, accurate and complete.<> {TERMS_COMPANY_NAME} </>
        reserves the right to suspend any account if the provided data is not truthful without any further notice.
      </Paragraph>

      <Title level={4}>4.2 Member account, password and security</Title>
      <Paragraph>
        Upon registration for<> {TERMS_COMPANY_NAME} </>services, you will receive an account registered with the
        username and password of your choice.
      </Paragraph>
      <Paragraph>
        You are responsible for maintaining the confidentiality of the password and account and are fully responsible
        for all activities that occur under your account. You agree to (a) immediately notify
        <> {TERMS_COMPANY_NAME} </>of any unauthorized use of your password or account or any other breach of security,
        and (b) ensure that you exit from your account at the end of each session.
        <> {TERMS_COMPANY_NAME} </>
        cannot and will not be liable for any loss or damage arising from your failure to comply with this section.
      </Paragraph>

      <Title level={4}>4.3 Your ability to edit and delete your account information</Title>
      <Paragraph>
        We give the ability to modify certain data introduced in <>{TERMS_COMPANY_NAME}</> Registration form, such as
        full name, country, phone, instant messaging network, instant messaging ID and password set upon registration.
      </Paragraph>
      <Paragraph>
        The change of the username and e-mail will be done by contacting one of <>{TERMS_COMPANY_NAME}</>’s
        representatives, upon checking the availability of the new desired username and e-mail.
      </Paragraph>

      <Title level={4}>4.4 Account termination</Title>
      <Paragraph>
        This Agreement shall commence upon your acceptance and remain in effect until terminated. This Agreement may be
        terminated by either Party upon one (1) business days´ notice. This Agreement shall terminate immediately upon
        the dissolution or insolvency of either Party or the breach of this Agreement by you. Mondiad network reserves
        the right, in its sole and absolute discretion, to terminate a campaign and remove any advertisements at any
        time for any reason.
      </Paragraph>
      <Paragraph>
        This Agreement will be blocked when the user`s Account has not been in use for more than three (3) months.
      </Paragraph>
      <Paragraph>
        You will receive a notification informing you that your account is blocked because of “Inactive account status”
        at account login. After deactivation, you will have 90 calendar days to restore your account. To do so, you have
        to login to your account and follow the steps described there. If your account is not reactivated within 90
        calendar days it will be deleted without the option to restore it.
      </Paragraph>
      <Paragraph>
        As a user, you acknowledge and agree that if your account is deleted, regardless of the reason, we will retain
        your data for at least two years, since we are required to keep the financial transactions stored.
      </Paragraph>

      <Title level={4}>4.5 Account inactivity fee</Title>
      <Paragraph>This condition applies automatically after 6 months of your account inactivity.</Paragraph>
      <Paragraph>
        If your account balance is above 0 EUR/USD, the remaining funds will be fully deducted from your account. We
        will start charging an inactivity fee of 5% of your account`s total balance, but never less than $10, or if your
        account total balance is less than $10, then, the whole balance will be charged as an inactivity fee.
        <br />
        <Text>If your account balance is 0 EUR/USD, the system will automatically block your account.</Text>
      </Paragraph>

      <a id="advertiser" />
      <Title level={2}>5. Advertiser Agreement</Title>
      <Title level={4}>5.1 The Service</Title>
      <Paragraph>
        <b>5.1.1.</b> In order to become an Advertiser, you must first submit an application for a Mondiad account at
        our website and be in compliance with the present Agreement (in case of using Self-service) or register as an
        Advertiser by contacting Mondiad network directly for acceptance, and not use any aliases or other means to mask
        your true identity or contact information. After we review your application, we will notify you of your
        acceptance or rejection as a Mondiad Advertiser. We may accept or reject your account registration at any time
        for any reason. Mondiad reserves the right to add, edit, remove or reclaim any account details (including your
        submissions) with or without your consent if deemed appropriate at Mondiad's sole discretion.
      </Paragraph>
      <Paragraph>
        <b>5.1.2.</b> By filing your account application or registering as an Advertiser you confirm your understanding
        and unreserved acceptance of the present Agreement and other terms and conditions of Mondiad network, including,
        but not limited to the Privacy Policy, published on our website concerning the Services, and confirm you are a
        duly authorized signatory, has full legal capacity and all the necessary authority to bind the individual,
        company or other entity, and hereby submitting a legally binding electronic signature and entering into a
        legally binding contract.
      </Paragraph>
      <Paragraph>
        <b>5.1.3.</b> In order to be eligible to become a Mondiad Advertiser, you shall not promote the following:
        <ul>
          <li>Racial, ethnic, political, hate-mongering or otherwise objectionable content;</li>
          <li>Investment, money-making opportunities or advice not permitted under law;</li>
          <li>Gratuitous violence or profanity;</li>
          <li>Material that defames, abuses, or threatens physical harm to others;</li>
          <li>
            Promotion of illegal substances or activities (e.g. illegal online gambling, “how to build a bomb”,
            counterfeiting money, etc.);
          </li>
          <li>Software Pirating (e.g., Warez, P2P, Bit torrent, Hotline, etc.);</li>
          <li>Hacking or Phreaking;</li>
          <li>
            Fraud, unofficial, untrue, false, misleading, invented, re-produced information, facts, news, offers,
            solutions, guidelines related to or aiming to treat in any way and at any level vulnerabilities of all kind,
            including but not limited to any physical, mental, psychological, social, religious, economic, scientific
            vulnerabilities;
          </li>
          <li>Any illegal activity whatsoever;</li>
          <li>
            Any spoofing, redirecting, or trafficking from adult-related websites in an effort to gain traffic; or
          </li>
          <li>Any other inappropriate activity is determined by us in our sole discretion.</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <b>5.1.4.</b> Mondiad network has the following Non-Acceptable Business rules for Advertisers: Where there are
        known or perceived links to terrorist organizations, military, arms and/or ammunition manufacture or sales;
        Where there is knowledge or suspicion of money laundering or terrorist financing; Where it is known or there are
        reasonable grounds for suspicion that a criminal offence has taken place; Where the client or any of the clients
        associated parties are subject to any sanctions measures; Where the client is undertaking an activity or trade
        within, from or into a country where that activity is subject to embargo and/or trade control restrictions;
        Advertisers of racist/pornographic/pressure group material or extreme political propaganda; Regulated entities
        that do not have the appropriate licensing; Extreme political and/or charitable organizations.
      </Paragraph>
      <Paragraph>
        <b>5.1.5.</b> Advertisers understand and accept that the Mondiad network does not allow and prohibits multiple
        account openings for each Advertiser. The Advertiser agrees not to fill in an account application and/or
        register as an Advertiser more than one time and/or hold more than one account with Mondiad network for any
        reason and/or in order to benefit in any way from any marketing promotional program/project and/or offer
        available for the Advertiser by Mondiad network.
      </Paragraph>
      <Paragraph>
        <b>5.1.6.</b> Mondiad network may allow multi-account applications/registrations/openings/holdings for an
        Advertiser if this is specifically and clearly predefined as accepted/permitted in any specific marketing
        promotional program/project and/or offer and/or in exceptional cases, at any time and for any reason Mondiad
        network considers acceptable and solely at its own discretion if the Advertiser submits such request by sending
        an email to{' '}
        <a target="_blank" href="mailto:contact@mondiad.com" onClick={useEmailClick()} rel="noreferrer">
          contact@mondiad.com
        </a>
        .
      </Paragraph>
      <Paragraph>
        <b>5.1.7.</b> There are the following methods of using the Service available – Self-Service or Management
        service. Self-Service assumes that access to the Service shall be provided through Advertiser`s personal
        account. Mondiad network support team may provide assistance upon your request, however, all the actions or
        modifications made through your account shall be deemed made solely by you. Management service assumes that
        assistance in using the Services and Program shall be provided by Mondiad network account managers.
      </Paragraph>
      <Paragraph>
        <b>5.1.8.</b> You may not transfer your account to anyone without explicit written permission from the Mondiad
        network and you may not use anyone else’s account or password at any time without the express permission and
        consent of the holder of that account. Mondiad network cannot and will not be liable for any loss or damage
        arising from your failure to comply with these obligations.
      </Paragraph>
      <Title level={4}>5.2 Advertiser Deposits</Title>
      <Paragraph>
        <b>5.2.1.</b> Mondiad network offers its Advertisers a wide range of deposit methods in order to provide
        convenient conditions for mutually beneficial cooperation.
      </Paragraph>
      <Paragraph>
        <b>5.2.2.</b> Mondiad network accepts money deposits through the following payment processors: PayPal, Paxum,
        Capitalist, Payoneer, Cryptocurrency, Bank Card, Voucher, and others by request.
      </Paragraph>
      <Title level={4}>5.3 Refund Policy</Title>
      <Paragraph>
        Our refund policy is very permissive. The advertisers may request a refund for the unspent funds at any time,
        while our finance team will process their request in maximum 7 business days (usually it takes around 24 to 48
        hours).
      </Paragraph>

      <a id="publisher" />
      <Title level={2}>6. Publisher Agreement</Title>
      <Title level={4}>6.1 The Service</Title>
      <Paragraph>
        <b>6.1.1.</b> In order to become a Publisher, you must first submit an application for a Mondiad account at our
        website and be in compliance with the present Agreement (in case of using Self-service) or register as a
        Publisher by contacting Mondiad network directly for acceptance, and not use any aliases or other means to mask
        your true identity or contact information. After we review your application, we will notify you of your
        acceptance or rejection as a Mondiad Publisher. We may accept or reject your account registration at any time
        for any reason. Mondiad reserves the right to add, edit, remove or reclaim any account details (including your
        submissions) with or without your consent if deemed appropriate at Mondiad sole discretion.
      </Paragraph>
      <Paragraph>
        <b>6.1.2.</b> By filing your account application or registering as a Publisher you confirm your understanding
        and unreserved acceptance of the present Agreement and other terms and conditions of Mondiad network, including,
        but not limited to the Privacy Policy, published at our website concerning the Services, and confirm you are a
        duly authorized signatory, has full legal capacity and all the necessary authority to bind the individual,
        company or other entity, and hereby submitting a legally binding electronic signature and entering into a
        legally binding contract.
      </Paragraph>
      <Paragraph>
        <b>6.1.3.</b> In order to be eligible to become a Mondiad Publisher, all websites must meet the following
        criteria:
        <br />
        Be content-based, not simply a list of links or advertisements, nor can the site be centered around making money
        off of our advertisers; Be fully functional at all levels; no “under construction” sites or sections; and Shall
        comply with the applicable legislation.
      </Paragraph>
      <Paragraph>
        <b>6.1.4.</b> The content of the Publisher’s website(s) or its affiliated website(s) can not include any
        material that infringes the rights of any third party or is in violation of any law, as bound by the law or
        determined by us in our sole discretion, including but not limited to the following:
        <ul>
          <li>Intellectual property rights;</li>
          <li>Racial, ethnic, political, hate-mongering or otherwise objectionable content;</li>
          <li>Investment, money-making opportunities or advice not permitted under law;</li>
          <li>Gratuitous violence or profanity;</li>
          <li>Material that defames, abuses, or threatens physical harm to others;</li>
          <li>
            Promotion of illegal substances or activities (e.g. illegal online gambling, “how to build a bomb”,
            counterfeiting money, etc.);
          </li>
          <li>Software Pirating (e.g., Warez, P2P, Bit torrent, Hotline, etc.);</li>
          <li>Hacking or Phreaking;</li>
          <li>
            Fraud, unofficial, untrue, false, misleading, invented, re-produced information, facts, news, offers,
            solutions, guidelines related to or aiming to treat in any way and at any level vulnerabilities of all kind,
            including but not limited to any physical, mental, psychological, social, religious, economic, scientific
            vulnerabilities;
          </li>
          <li>Any illegal activity whatsoever;</li>
          <li>
            Any spoofing, redirecting, or trafficking from adult-related websites in an effort to gain traffic; or
          </li>
          <li>Any other inappropriate activity is determined by us in our sole discretion</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <b>6.1.5.</b> Mondiad network has the following Non Acceptable Business rules for Publishers: Where there are
        known or perceived links to terrorist organizations, military, arms and/or ammunition manufacture or sales;
        Where there is knowledge or suspicion of money laundering or terrorist financing; Where it is known or there are
        reasonable grounds for suspicion that a criminal offense has taken place; Where the client or any of the clients
        associated parties are subject to any sanctions measures; Where the client is undertaking an activity or trade
        within, from or into a country where that activity is subject to embargo and/or trade control restrictions;
        Producers/publishers of racist/pornographic/pressure group material or extreme political propaganda; Regulated
        entities that do not have the appropriate licensing; Extreme political and/or charitable organizations.
      </Paragraph>
      <Paragraph>
        <b>6.1.6.</b> Publisher understands and accepts that Mondiad network does not allow and prohibits the multiple
        account opening for each Publisher. Publisher agrees not to fill in an account application and/or register as a
        Publisher more than one time and/or hold more than one account with Mondiad network for any reason and/or in
        order to benefit in any way from any marketing promotional program/project and/or offer available for the
        Publisher by Mondiad network.
      </Paragraph>
      <Paragraph>
        <b>6.1.7.</b> Mondiad network may allow multi-account applications/registrations/openings/holdings for a
        Publisher if this is specifically and clearly predefined as accepted/permitted in any specific marketing
        promotional program/project and/or offer and/or in exceptional cases, at any time and for any reason Mondiad
        network considers acceptable and solely at its own discretion if the Publisher submits such request by sending
        an email to{' '}
        <a target="_blank" href="mailto:contact@mondiad.com" onClick={useEmailClick()} rel="noreferrer">
          contact@mondiad.com
        </a>
        .
      </Paragraph>
      <Paragraph>
        <b>6.1.8.</b> There are the following methods of using the Service available – Self-Service or Management
        service. Self-Service assumes that access to the Service shall be provided through Publisher’s personal account.
        Mondiad network support team may provide assistance upon your request, however, all the actions or modifications
        made through your account shall be deemed made solely by you. Management service assumes that assistance of
        using the Services and Program shall be provided by Mondiad network account managers.
      </Paragraph>
      <Paragraph>
        <b>6.1.9.</b> You may not transfer your account to anyone without explicit written permission of Mondiad network
        and you may not use anyone else’s account or password at any time without the express permission and consent of
        the holder of that account. Mondiad network cannot and will not be liable for any loss or damage arising from
        your failure to comply with these obligations.
      </Paragraph>

      <Title level={4}>6.2 Placement of Ads</Title>
      <Paragraph>
        <b>6.2.1.</b> Publisher shall NOT place any advertisements of Mondiad network advertisers attracted through the
        Service on alternative publishers or websites without written consent and approval of Mondiad network. Publisher
        will not place an advertisement on pornographic/offensive, and/or warez, and/or illegal MP3 sites/directories,
        and/or P2P/Bit-Torrent sites, and/or Spyware or malicious code of any sort and/or alternatively questionable
        areas. In the case whereby advertisements are placed in such sites/directories, Mondiad network reserves the
        right to withhold payment for the entire campaign and/or submit an immediate legal action against the Publisher
        and/or set a monetary fine in the amount based on the damages caused to Mondiad network.
      </Paragraph>
      <Paragraph>
        <b>6.2.2.</b> Mondiad network does not check or control the activities or contents at your website, but all the
        services may be rejected and we reserve the right to delete your account, withhold and freeze all fees and
        remunerations if you engage in fraudulent or illegal activity.
      </Paragraph>

      <Title level={4}>6.3 Online reports</Title>
      <Paragraph>
        <b>6.3.1.</b> Use of the Service shall be carried out on a monthly basis. For the purpose of the present
        Agreement, a calendar month shall be deemed as a reporting period (“Reporting Period”).
      </Paragraph>
      <Paragraph>
        <b>6.3.2.</b> During the month Publisher may track online reports within Mondiad network reporting system in the
        Publisher’s personal account reports page, which are only estimated numbers subject to being adjusted within 15
        days after the end of the Reporting Period. In all cases, we will use commercially reasonable methods and
        practices to direct and measure traffic. Campaigns may be adjusted at any time by Mondiad network team to comply
        with the advertiser´s ad serving stats. At the end of the Reporting Period the reports are frozen and within 15
        days will include the definitive numbers of earnings. For avoidance of doubt, Mondiad network reporting system
        (stats) will be prevailing in any case.
      </Paragraph>

      <Title level={4}>6.4 Self-Billing</Title>
      <Paragraph>
        <b>6.4.1.</b> Hereby the Publisher expressly orders Mondiad network to generate and issue the Publisher’s
        invoices on behalf of the Publisher. Prior to making any payment to a Publisher, Mondiad network will generate
        automatically through the Program the invoice on behalf of such Publisher. Furthermore, the Publisher expressly
        agree that the Program will generate the said invoices based on the stats provided by the Mondiad network
        reporting system and agree that such stats is accurate, fully and legally compliant for the purposes of
        invoicing and taxation.
      </Paragraph>
      <Paragraph>
        <b>6.4.2.</b> Any Publisher residing in the European Union who has provided a VAT number expressly warrants that
        such VAT number is, in its own country, valid for the issuance of VAT-exempt invoices to Mondiad network. The
        Publisher expressly accepts to be solely liable for any error, direct or indirect loss or damage arising from
        the inaccuracy or non-compliance of such data or the breach of any of the aforementioned warranties and,
        accordingly, the Publisher will hold Mondiad network harmless from any of the direct or indirect loss or
        damages. Publisher hereby confirms that another VAT invoice won’t be issued.
      </Paragraph>
      <Paragraph>
        <b>6.4.3.</b> Parties hereby agree to notify each other if they:
        <ul>
          <li>change their VAT registration number;</li>
          <li>cease to be VAT registered;</li>
          <li>sell their business, or part of their business;</li>
          <li>to notify each other about any changes in their payment details</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <b>6.4.4.</b> Notice given in accordance with the conditions of clause 17.3 is also to be considered as your
        confirmation to issue self-billing invoices in altered conditions.
      </Paragraph>
      <Paragraph>
        <b>6.4.5.</b> In case there is any claim, administrative proceeding from any authority, dispute or conflict, in
        any way due to the inaccuracy or non-compliance of such data provided by the Publisher, Mondiad network is
        expressly authorized to retain any payments due to the Publisher until such incident has been resolved.
      </Paragraph>

      <Title level={4}>6.5 Publisher Earnings</Title>
      <Paragraph>
        <b>6.5.1.</b> Cost of using Service depends on the amount and scope of advertising campaigns carried out on
        Publishers websites during the reporting period based on ads placements generated by Mondiad network reporting
        system (stats), available in your personal account. All reported statistics for the purposes of billing and
        general delivery reporting are based on Mondiad network reporting system only.
      </Paragraph>
      <Paragraph>
        <b>6.5.2.</b> In the event that the Publisher believes that there is a discrepancy in Mondiad network’s
        reporting system, the Publisher must provide Mondiad network with a reasoned report of such discrepancy within
        three (3) calendar days from receipt of Mondiad network’s reports. Otherwise, Mondiad network shall not be
        liable for such discrepancy and will calculate earnings on basis of its reporting system. If the parties are
        unable to reach an agreement regarding the discrepancy, then Mondiad network stats and reports shall prevail.
      </Paragraph>
      <Paragraph>
        <b>6.5.3.</b> Mondiad network is entitled to make adjustments in Publisher’s account in one of the following
        cases:
        <ul>
          <li>To pay promotions and bonuses</li>
          <li>Due to technical reasons</li>
          <li>Due to Publisher’s fraudulent activity</li>
          <li>On the basis of additional agreements with You</li>
          <li>Due to Advertiser’s complaints or refunds</li>
        </ul>
      </Paragraph>

      <Title level={4}>6.6 Publisher Payments</Title>
      <Paragraph>
        <b>6.6.1.</b> Mondiad network offers its Publishers a wide range of payment methods in order to provide
        convenient conditions for mutually beneficial cooperation.
      </Paragraph>
      <Paragraph>
        Mondiad network has the following payment terms:
        <ul>
          <li>
            Self-Service billing type:
            <ul>
              <li>
                for newly registered Publishers the very first payout may be proceeded not earlier than 14 calendar days
                from the date the first campaign starts;
              </li>
              <li>
                the second and the following payouts to proceed either on a weekly basis (every Thursday) with a Hold 4
                days (e.g. if payment request has been filed through your personal account on Thursday, the payment day
                will be Monday next week), or at Net 35 days basis. Payment terms variations available in Your personal
                account.
              </li>
            </ul>
          </li>
          <li>For Management service, Mondiad network will payout the Publisher's revenue at Net 35 days basis.</li>
          <li>
            Minimum payment amounts:
            <ul>
              <li>wire transfers - min 1000 USD;</li>
              <li>
                payment service providers - according to the limits set forth in your personal account subject to the
                selected payment method.
              </li>
            </ul>
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        If the balance is less than the limits above, Mondiad network will add the amount to the next payment until the
        account balance will reach the specified minimum. The specified minimum amounts can be adjusted with the
        agreement of all parties hereto however such payments may be subject to banking and administration fees.
      </Paragraph>
      <Paragraph>
        <b>6.6.2.</b> Mondiad network acts as a third party for advertisers, therefore Publisher understands and agrees
        that payment for Publisher’s revenue is dependent upon payments from advertisers to Mondiad network that it has
        received without any restrictions. You hereby release Mondiad network from any claim for Publisher’s revenue if
        Mondiad network did not receive funds from the advertiser. Publisher shall hold Mondiad network harmless and
        indemnify it from any claims or liability related to such unpaid revenue.
      </Paragraph>
      <Paragraph>
        <b>6.6.3.</b> Mondiad network provides the ability to perform payments by using payment service providers.
        Publisher shall have the right to select any payment service provider available. You agree that Mondiad network
        is not responsible for any actions made by the payment service provider including but not limited to any
        additional transaction fees, banking commissions or currency fees applied to your transaction. All payments
        shall include the above-mentioned fees and commission, if applicable.
      </Paragraph>
      <Paragraph>
        <b>6.6.4.</b> Publisher is responsible for all applicable taxes associated with provided Services, other than
        taxes based on Mondiad network income. Publisher shall indemnify Mondiad network against all losses suffered or
        incurred by the Mondiad arising out of or in connection with any payment made to the Publisher.
      </Paragraph>
      <Paragraph>
        <b>6.6.5.</b> The publisher is responsible to supply valid payment details in the personal account of our
        Service, if details are wrong or if the Publisher changes its payment details, it is the Publisher’s
        responsibility to notify by mail 14 days before payment due date. Publisher will bear payments fees if required.
        In any event, all payments will be made at the payment details specified in your personal account in our
        Service.
      </Paragraph>
      <Paragraph>
        <b>6.6.6.</b> All payments are processed automatically. We may, in our sole discretion, refuse to process a
        payment (and may place a payment hold) on any part of your account for any reason, block your account, and
        terminate this Agreement, including if we have a reasonable suspicion that you have breached any clause of this
        Agreement. We also reserve the right to set off any amount you owe us, including for breaches of this Agreement.
        We assume no responsibility for paying any taxes on payments made to you, and you acknowledge and agree that it
        is your complete and sole responsibility to pay for all taxes as a consequence of your participation in the
        Program.
      </Paragraph>
      <Paragraph>
        <b>6.6.7.</b> Hereby you represent and warrant to provide Mondiad network with all the documentation or its
        equivalents, needed for identification of the parties, ascertainment of the legal fact and fulfillment of its
        obligations under this Agreement, within 15 business days from the date of request. In certain cases, we may
        withhold all payments until we will receive relevant documentation from you.
      </Paragraph>
      <Paragraph>
        <b>6.6.8.</b> You on your own shall ensure the ability to receive payments from Mondiad network to a specified
        bank account or at a relevant payment provider. If the receipt of remuneration or other payment is delayed or
        failed because of your non-compliance with this clause 6 (including if the failure or delay is caused by a
        third-party payment service provider you are using), Mondiad network shall not be responsible for violation of
        terms of payment.
      </Paragraph>
      <Paragraph>
        <b>6.6.9.</b> If you believe that any fault in the transaction has taken place, you agree to notify us
        immediately, and We will make all possible efforts to eliminate delays or errors in payment processing. Unless
        your claim has been submitted within 30 days after the charge, you will have waived, to the fullest extent
        permitted by law, all claims against Mondiad network related to the transaction. If you experience a technical
        failure or interruption of services that causes your funding transaction to fail, you may request that your
        transaction be completed at a later time.
      </Paragraph>
      <Paragraph>
        <b>6.6.10.</b> By entering into this Agreement, you agree to receive Publisher’s revenue as from Mondiad
        network, or from its affiliates, subsidiaries, agents, sub-contractors or distributors.
      </Paragraph>

      <Title level={2}>7. Fraudulent activity</Title>
      <Paragraph>
        Fraud will be considered any activity conducted by any existing client of <>{TERMS_COMPANY_NAME}</> services if
        the Terms of Service will be breached or if any of the <>{TERMS_COMPANY_NAME}</> representatives will consider
        so taken in consideration different suspicions for fraudulent activities (e.g. connections with accounts
        suspended in the past for similar activities).
      </Paragraph>
      <Paragraph>
        By using <>{TERMS_COMPANY_NAME}</> services you agree that the methods used by <>{TERMS_COMPANY_NAME}</>{' '}
        representatives to determine if a client has breached the Terms of Use or is suspicious for fraudulent
        activities will not be disclosed to you.
      </Paragraph>
      <Paragraph>
        Any flaws in <>{TERMS_COMPANY_NAME}</> services, presentation website, user dashboard or any other section owned
        by <>{TERMS_COMPANY_NAME}</> will be provided to <>{TERMS_COMPANY_NAME}</> representatives directly. Use of any
        of these flaws will lead to account suspension.
      </Paragraph>
      <Paragraph>
        You acknowledge, consent and agree that <>{TERMS_COMPANY_NAME}</> may disclose your account information and
        content if required to do so by law or in a good faith belief that such access preservation or disclosure is
        reasonably necessary to: (1) comply with legal process; (2) enforce the TOS; (3) respond to claims that any
        Content violates the rights of third parties; (4) respond to your requests for customer service; or (5) protect
        the rights, property or personal safety of Yahoo, its users and the public.
      </Paragraph>

      <Title level={4}>7.1 Member conduct</Title>
      <Paragraph>
        By registering to <>{TERMS_COMPANY_NAME}</> services, we have the right to request additional information about
        your transactions if under the suspicion the transaction was not authorized or made by the owner of the account.
      </Paragraph>
      <Paragraph>
        We reserve the right to terminate or suspend any account that has used stolen, hacked or unauthorized payment
        accounts for <>{TERMS_COMPANY_NAME}</> services without any prior notice.
      </Paragraph>
      <Paragraph>
        For transactions from <>{TERMS_COMPANY_NAME}</> to client, if under the suspicion the account security was
        breached, a <>{TERMS_COMPANY_NAME}</> representative will request confirmation from the account owner. Failure
        to reply to the confirmation e-mail will result in delay or cancellation of the transaction, as well as
        cancellation of the <>{TERMS_COMPANY_NAME}</> account password.
      </Paragraph>
      <Paragraph>
        YOU MAY NOT CHEAT, DEFRAUD OR MISLEAD US, OR ATTEMPT TO CHEAT, DEFRAUD OR MISLEAD US, IN ANY MANNER.
      </Paragraph>
      <Paragraph>
        You are expressly prohibited from using any means, devices, or arrangements to commit fraud, violate any
        applicable law, interfere with other affiliates, or falsify information in connection with the Services, or
        generating of remuneration or exceed your permitted access to Mondiad network Service. You are forbidden from
        using any preference/method resulting in the re-direction of the user to your landing page when such user has at
        least once previously chosen through a certain action to leave your page. These prohibited activities include
        but are not limited to: framing an ad-banner’s click-through destination, invisible iframe, auto-spawning of
        browsers, running “spiders”/”bots”, and automatic redirecting of users or any other technique of generating
        automatic or fraudulent click-through and/or impressions. Ads may not be placed on an automatically reloaded
        page. In any case, Mondiad network shall make all determinations about fraudulent activity at its sole
        discretion.
      </Paragraph>

      <Title level={4}>7.2 Advertiser fraud</Title>
      <Paragraph>
        We will check each promotion before approval and while running for each of our advertisers to block and avoid
        any possible fraudulent activity.
      </Paragraph>
      <Paragraph>
        The user of software or tools that will manipulate the device used by the visitor or the website where the
        advertisement is displayed for any purpose other than the described <>{TERMS_COMPANY_NAME}</> service will
        result in the termination of the account without the right to a refund.
      </Paragraph>
      <Paragraph>
        Advertisers will receive the ads purchased through a link of their choice. In case the link or any middle link
        between <>{TERMS_COMPANY_NAME}</> and the offer is marked by 3rd party services designed to detect and block
        malware, phishing or other type of online fraud, we reserve the right to suspend your account or apply monetary
        penalties, taking in consideration the severity and frequency of such promotions.
      </Paragraph>
      <Paragraph>
        We also reserve the right to suspend your account or apply monetary penalties if any of the below offers are
        promoted through <>{TERMS_COMPANY_NAME}</> services, depending on the severity and
      </Paragraph>
      <Paragraph>
        Included in the list of not allowed content to be promoted is:
        <ul>
          <li>APK/EXE automatic download without a delay of at least 10 seconds and a proper landing page;</li>
          <li>Malware/phishing campaigns;</li>
          <li>Technical support, fake support or related;</li>
          <li>Fake, aggressive or misleading ads (fake flash player, fake antivirus, etc.);</li>
          <li>Sneaky redirects and cloaking;</li>
          <li>Landing pages without content or not working;</li>
          <li>Violent, hate (based on race, religion, gender, age, sexual orientation or gender identity);</li>
          <li>Incest, necrophilia, pedophilia, zoophilia, bestiality and more;</li>
          <li>Any offer that has content forbidden in the country promoted by law.</li>
          <li>
            It is forbidden to buy traffic with the purpose of sending it back to <>{TERMS_COMPANY_NAME}</> for
            monetization.
          </li>
        </ul>
      </Paragraph>

      <Title level={4}>7.3 Publisher fraud</Title>
      <Paragraph>
        We will perform random analysis over publisher’s accounts to verify the quality of the ads provided to{' '}
        <>{TERMS_COMPANY_NAME}</>. Based on the results of each analysis the <>{TERMS_COMPANY_NAME}</> representative
        performing the analysis and if the results are showing abnormal behavior or the results will have a very
        low-quality score he will decide if to retain partial amount of the earnings or to suspend the account.
        Additional analysis of the publisher’s accounts will be performed if suspicion of fraudulent activity or the
        reports received from active advertisers will point to abnormal behavior and the <>{TERMS_COMPANY_NAME}</>{' '}
        representative will decide if and what action is to be taken.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> is not tolerating the publishers who are sending traffic generated from the same
        sources on multiple accounts. We reserve the right to block the publishers who can harm the performance and
        optimization of our advertisers campaigns, due to these actions.
      </Paragraph>
      <Paragraph>
        A <>{TERMS_COMPANY_NAME}</> publisher will have the <>{TERMS_COMPANY_NAME}</> account suspended if we receive
        notification and proof from 3rd party about the use with the purpose of monetization of a website that is not
        owned or managed by the <>{TERMS_COMPANY_NAME}</> publisher.
      </Paragraph>
      <Paragraph>
        If the Publisher is suspected of any fraudulent activity Mondiad network shall have the right to ban Your
        Publisher Account, to withhold your account balance, and to take all necessary legal actions to restore the
        damage caused by this violation. All advertising campaigns carried out on Publisher’s websites with fraudulent
        activities are not subject to payment.
      </Paragraph>

      <Title level={2}>8. Privacy policy</Title>
      <Title level={4}>8.1 General information</Title>
      <Paragraph>
        The Terms of Service constitutes the entire agreement between you and <>{TERMS_COMPANY_NAME}</> and governs your
        use of the <>{TERMS_COMPANY_NAME}</> services, superseding any prior version of this Terms of Service between
        you and <>{TERMS_COMPANY_NAME}</> with respect to the <>{TERMS_COMPANY_NAME}</> services.
      </Paragraph>
      <Title level={4}>8.2 Information collection and use</Title>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> collects personal information when you register. Upon registration, we ask for
        information such as name, e-mail address, instant messaging address and country. For some type of deposits, if a{' '}
        <>{TERMS_COMPANY_NAME}</> representative will consider necessary, we may request for additional evidence of
        ownership and authorization for those transactions such as an address, proof of residence or others, depending
        on the case.
      </Paragraph>
      <Paragraph>
        For transactions made from client to <>{TERMS_COMPANY_NAME}</>, the client can introduce billing info such as
        company name, address, country, tax ID, company number and other information for the purpose of downloading
        invoices.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> stores all communication content done between you and <>{TERMS_COMPANY_NAME}</>{' '}
        representatives, including e-mails and instant messaging conversations.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> uses the information provided for the following purposes: verify the authenticity of
        each of our users, authorization of transaction and ownership of websites, offers and accounts.
      </Paragraph>
      <Paragraph>
        The data provided to <>{TERMS_COMPANY_NAME}</> will be used solely for <>{TERMS_COMPANY_NAME}</> activities and
        it does not include any 3rd party.
      </Paragraph>

      <Title level={4}>8.3 Sharing and disclosure</Title>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> will not sell, rent or share personal information submitted through{' '}
        <>{TERMS_COMPANY_NAME}</> registration form or as requested by <>{TERMS_COMPANY_NAME}</> representatives, except
        under the following circumstances:
        <ul>
          <li>
            We respond to subpoenas, court order or legal process (such as law enforcement requests), or to establish
            our legal rights or defend against legal claims.
          </li>
          <li>
            When we believe it is necessary to share information to investigate, prevent or stop any illegal activities,
            suspected fraud, situations involving potential threats to the physical safety of any person, violation of{' '}
            <>{TERMS_COMPANY_NAME}</>’s Terms of Service, or as otherwise required by law.
          </li>
        </ul>
      </Paragraph>

      <Title level={4}>8.4 Confidentiality</Title>
      <Paragraph>
        <b>8.4.1.</b> Each Party (a “Receiving Party“) understands that the other Party (a “Disclosing Party“) may
        disclose information of a confidential nature including, without limitation, product information, data, pricing,
        financial information, software, specifications, research and development and proprietary algorithms, stats and
        reports, personal data or other materials that is disclosed in a manner in which the Disclosing Party reasonably
        communicated, or the Receiving Party should reasonably have understood under the circumstances that the
        disclosure should be treated as confidential, whether or not the specific designation “confidential” or any
        similar designation is used (“Confidential Information“).
      </Paragraph>
      <Paragraph>
        <b>8.4.2.</b> The Receiving Party agrees, for itself and its agents and employees, that it will not publish,
        disclose or otherwise divulge or use for its own purposes any Confidential Information of the Disclosing Party
        furnished to it by such Disclosing Party without the prior written approval of the Disclosing Party in each
        instance. Neither party will make any public announcement regarding the existence or content of the Agreement
        without the other’s prior written approval.
      </Paragraph>
      <Paragraph>
        <b>8.4.3.</b> The Parties agree that if the disclosure is made to their professional advisors, auditors or
        bankers this shall be done subject to each Party procuring each such recipient’s agreement to keep such
        information confidential to the same extent as if such recipient were Party to this agreement.
      </Paragraph>
      <Paragraph>
        <b>8.4.4.</b> The foregoing obligations under this section 15 shall not extend to any information to the extent
        that the Receiving Party can demonstrate that such information (i) was at the time of disclosure or, to the
        extent that such information thereafter becomes through no fault of the Receiving Party, a part of the public
        domain by publication or otherwise; (ii) was already properly and lawfully in the Receiving Party’s possession
        at the time it was received by the Receiving Party free from any obligation of confidentiality, (iii) was or is
        lawfully received by the Receiving Party from a third Party who was under no obligation of confidentiality to
        the Disclosing Party with respect thereto, or (iv) is independently developed by the Receiving Party or its
        independent contractors who did not have access to the Disclosing Party’s Confidential Information or (v)
        express written consent has been given prior to disclosure.
      </Paragraph>
      <Paragraph>
        <b>8.4.5.</b> In the event that the Receiving Party is required to disclose Confidential Information in
        accordance with judicial or regulatory or governmental order or requirement, or any tax authority to which that
        Party is subject or submits, wherever situated, whether or not the requirement for information has the force of
        law the Receiving Party shall promptly notify the Disclosing Party in order to allow such Party to contest the
        order or requirement or seek confidential treatment for such information.
      </Paragraph>
      <Paragraph>
        <b>8.4.6.</b> Upon termination or expiration of this Agreement, upon the request of a Disclosing Party, the
        Receiving Party agrees to return to the other all of such other Party’s Confidential Information or to certify
        to the Disclosing Party in writing that all such material has been destroyed, however, destruction is only
        permitted after Disclosing Party’s prior approval.
      </Paragraph>

      <Title level={4}>8.5 No 3rd party beneficiaries</Title>
      <Paragraph>
        You agree that, except as otherwise expressly provided in this TOS, there shall be no third-party beneficiaries
        to this agreement.
      </Paragraph>

      <Title level={4}>8.6 Cookies and similar technologies</Title>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> doesn’t use any cookies other than the ones that can be saved on your computer for
        some features (e.g. settings).
      </Paragraph>

      <Title level={4}>8.7 Changes to this policy</Title>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> will reserve the right to apply changes to this policy without prior or further
        notice.
      </Paragraph>

      <Title level={2}>9. Intellectual Property Rights</Title>
      <Paragraph>
        <b>9.1.</b> Hereby we grant you a non-exclusive, non-transferable, revocable right to use Mondiad network
        Service and to access our website through our Service only in accordance with the terms and conditions of this
        Agreement.
      </Paragraph>
      <Paragraph>
        <b>9.2.</b> You may not alter, modify, manipulate or create derivative works of Mondiad network or any of our
        graphics, creative, copy or other materials owned by, or licensed to Mondiad network in any way. We may revoke
        your license anytime by giving you written notice. Except as expressly stated herein, nothing in this Agreement
        is intended to grant you any rights to any of Mondiad network’ trademarks, service marks, copyrights, patents or
        trade secrets. You agree that we may use any suggestion, comment or recommendation you choose to provide to
        Mondiad network without compensation. All rights not expressly granted in this Agreement are reserved by Mondiad
        network.
      </Paragraph>
      <Paragraph>
        <b>9.3.</b> Your use of the Service shall be governed by and subject to the laws and regulations regarding
        copyright ownership and terms of use of the intellectual property. You represent, warrant, and covenant that you
        do not upload, download, display, perform, transmit, or otherwise distribute any object in violation of any
        third party’s copyrights, trademarks, or other intellectual property rights. You represent, warrant, and
        covenant that you abide by the laws regarding copyright ownership and use of the intellectual property and you
        shall be solely responsible for any violations of any relevant laws and for any infringements of third-party
        rights caused by you.
      </Paragraph>
      <Paragraph>
        <b>9.4.</b> ALL THE PARTIES HEREBY AGREE THAT Mondiad network DOES NOT HAVE ANY AUTHORITY OR ABILITY TO CONTROL
        CONTENT AT PUBLISHER’S WEBSITE(S) AND FOR THIS REASON, WE CANNOT BEAR ANY RESPONSIBILITY REGARDING BREACHING OF
        ANY THIRD PARTY’S INTELLECTUAL PROPERTY RIGHTS OR OTHER RIGHTS.
      </Paragraph>

      <Title level={2}>10. Waiver</Title>
      <Title level={4}>10.1 Representations, Warranties and Covenants</Title>
      <Paragraph>
        <ul>
          <li>
            You represent, warrant, and covenant that your website is in compliance with all applicable laws and terms
            and conditions of the present Agreement, and does not contain or promote, nor links to another website that
            contains, libelous, defamatory, abusive, violent, prejudicial, obscene, infringing, sexually explicit or
            illegal content, including copyright ownership infringements and unlawful use of intellectual property;
          </li>
          <li>
            You agree not to promote via website or link to websites containing any pornographic, racial, ethnic,
            political, software pirating (e.g. Warez) or hacking, hate-mongering, or otherwise objectionable content;
          </li>
          <li>
            You agree not to engage in any illegal activity, in accordance with applicable law, whatsoever, is not
            allowed;
          </li>
          <li>
            You represent and warrant that you own or have legal rights to use and distribute all content, copyrighted
            material, trademarked materials, products, and services displayed on your website; you agree not to use
            deceit when marketing advertiser’s offers or presenting these offers to consumers; you have the right,
            power, and authority to enter into this Agreement and grant the rights specified herein;
          </li>
          <li>
            You will not attempt in any way to alter, modify, eliminate, conceal, or otherwise render inoperable or
            ineffective the website tags, source codes, links, pixels, modules, or other data provided by or obtained
            from Mondiad network that allows Mondiad network to measure ad performance and provide its service (“Site
            Data”);
          </li>
          <li>
            If instructed to do so by Mondiad network and/or if this Agreement terminates, you will immediately remove
            and discontinue the use of any Site Data;
          </li>
          <li>
            You acknowledge that Mondiad network does not represent, warrant, or make any specific or implied promises
            as to the successful use of Service;
          </li>
          <li>
            You agree to display the creative exactly as it appears on the Program and will not alter any creative that
            has been submitted to the Site;
          </li>
          <li>
            You agree to display the creative exactly as it appears on the Service and will not alter any creative that
            has been placed through the Service;
          </li>
          <li>
            If you are notified that fraudulent activities may be occurring on your website, and you do not take any
            actions to stop the fraudulent activities, then you are responsible for all associated costs and legal fees
            resulting in these fraudulent activities;
          </li>
          <li>
            You represent, warrant, and covenant that you will not take any action that imposes, or may impose, in our
            sole discretion, an unreasonable or disproportionately large load on our technology infrastructure or
            otherwise make excessive demands on it;
          </li>
          <li>
            You may not disable, circumvent or otherwise interfere with security-related features of our Service or
            features that prevent or restrict use or copying of any part of our Service, or which enforce limitations on
            the use of our Service;
          </li>
          <li>
            Hereby You irrevocably authorize Mondiad network to transfer a request received by Mondiad network to
            provide information for the payment directly to your financial institution available;
          </li>
          <li>
            You represent, warrant, and covenant that your website does not contain any sexual or erotic material that
            depicts persons under the age of eighteen (18) or in a manner that suggests that they are under the age of
            eighteen (18);
          </li>
          <li>
            If any errors or undesirable results occur due to no fault of Mondiad network, Mondiad network shall not be
            responsible for losses and you may not be compensated;
          </li>
          <li>
            Publisher undertakes to ensure that its servers support the traffic directed to ad campaign through our
            Service. Mondiad network takes no responsibility for all the consequences in the event your servers cannot
            support the traffic directed to your website. You shall test your website to insure its correct appearance
            in different web browsers, devices, or systems and optimize it if necessary.
          </li>
          <li>
            You acknowledge that every case of violation of the terms of this Agreement will lead to material and
            business standing losses of Mondiad network in the amount of at least US $ 1,000. Therefore, we reserve the
            right to recover damages caused in the specified amount, or the amount of actually incurred losses, in the
            event of your breach of contract. Such losses may be deducted from the balance of your personal account in
            the Service.
          </li>
        </ul>
      </Paragraph>

      <Title level={4}>10.2 Limitation of Liability; Disclaimer of Warranty</Title>
      <Paragraph>
        IN NO EVENT SHALL MONDIAD NETWORK BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM YOUR USE OF THE SERVICE,
        OPERATION OF A PROGRAM, OR YOUR DISPLAY OF ANY PROGRAM CREATIVE ON YOUR WEBSITE, INCLUDING BUT NOT LIMITED TO
        BROKEN IMAGES, SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES, EVEN IF MONDIAD network HAS
        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. MONDIAD network IS ONLY THE TOOL FOR ADS CAMPAIGNS CONNECTING
        ADVERTISERS AND PUBLISHERS THROUGH ITS SERVICE. THE INFORMATION, CONTENT AND OTHER MONDIAD NETWORK SERVICES ARE
        PROVIDED ON AN “AS IS” BASIS WITH NO WARRANTY. YOU USE THE SERVICE AND RUN PROGRAMS AT YOUR OWN RISK. TO THE
        MAXIMUM EXTENT PERMITTED BY LAW, MONDIAD network DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND,
        EXPRESS OR IMPLIED, WITH RESPECT TO THE OPERATION OF THE SERVICE, THE INFORMATION, AND CONTENT INCLUDED ON THE
        SERVICE AND PROVIDED BY MONDIAD NETWORK, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY AND
        FITNESS FOR A PARTICULAR PURPOSE. MONDIAD NETWORK DOES NOT REPRESENT OR WARRANT THAT THE INFORMATION ON THIS
        SERVICE OR WEBSITE OR PROVIDED BY MONDIAD NETWORK IS ACCURATE, COMPLETE, OR CURRENT.
      </Paragraph>

      <Title level={4}>10.3 Indemnity</Title>
      <Paragraph>
        You shall indemnify, defend and hold Mondiad network harmless from and against any and all claims, allegations,
        liabilities, costs, and expenses (including reasonable attorneys´ fees) which may be incurred by or to the third
        parties arising out of your: (a) improper use of the Service; (b) improper operation of a Program; or (c) breach
        or violation of any clause of this Agreement or other mutual agreement of its parties.
      </Paragraph>

      <Title level={4}>10.4 Severability</Title>
      <Paragraph>
        If any provision of this Agreement is held to be invalid, illegal or unenforceable for any reason, such
        invalidity, illegality or unenforceability shall not affect any other provisions of this Agreement, and this
        Agreement shall be construed as if such invalid, illegal or unenforceable provision had not been contained
        herein.
      </Paragraph>

      <Title level={2}>11. Force Majeure</Title>
      <Paragraph>
        Neither Party shall be liable to the other by reason of failure or delay in the performance of its obligations
        hereunder on account of Acts of God, fires, storms, war, governmental action, labor conditions, earthquakes,
        natural disasters, interruption in internet service or any other cause which is beyond the reasonable control of
        such Party. The party referring to such force majeure circumstances shall notify the other party on arising
        within 3 working days from the date of its occurrence with the relevant evidence.
      </Paragraph>

      <Title level={2}>12. Miscellaneous</Title>
      <Paragraph>
        <b>12.1.</b> This Agreement contains the sole and entire agreement and understanding between the Parties
        relating to the subject matter herein, and merges all prior discussions, whether through officers, directors,
        salespersons, employees or consultants.
      </Paragraph>
      <Paragraph>
        <b>12.2.</b> Present Agreement is the principal document in legal relationship of the Parties, and shall be
        deemed an entire agreement of the Parties. In case of contradictions in using Service or Program, present
        Agreement shall prevail in any case.
      </Paragraph>
      <Paragraph>
        <b>12.3.</b> Each Party is an independent contractor and not a partner, joint venture or employee of the other.
        All notices shall be sent to the addresses submitted by you when signing up for the Service by certified mail,
        fax, email or courier.
      </Paragraph>
      <Paragraph>
        <b>12.4.</b> Mondiad network reserves the right to change any terms and conditions of this Agreement at any time
        unilaterally. The Publisher shall be informed of such amendments by relevant notice in personal account or
        through the information being made available on Mondiad network’s website. The Publisher shall be deemed to have
        received such notice within two (2) weeks of the notice being sent by e-mail or made available in Publisher’s
        personal account on Mondiad network’s website. You may refer to contract revisions in our website –
        www.Mondiad.com. The terms and conditions of the present Publisher Agreement (as published on www.Mondiad.com
        (the “Terms”) bind the parties from the date signed or the date service is provided and shall apply to each and
        any services provided by Mondiad network. This Agreement shall take precedence over any other terms and
        conditions issued or stated or referenced to apply relating to the services provided by Mondiad network.
      </Paragraph>
      <Paragraph>
        <b>12.5.</b> Representations and warranties of Publisher set forth in this Agreement (in particular, Section 15)
        hereof shall survive closing for a period of one (1) year from the termination date.
      </Paragraph>
      <Paragraph>
        <b>12.6.</b> No claim for a breach of any representation or warranty by Mondiad network shall be actionable or
        payable if the breach in question results from or is based on a condition, state of facts or other matter which
        was disclosed to Publisher and/or actually known by Publisher prior to termination.
      </Paragraph>
      <Paragraph>
        <b>12.7.</b> Publisher acknowledges and agrees that entering into this Agreement it has not relied and is not
        relying on any representations, warranties or other statements whatsoever, whether written or oral other than
        those expressly set out in this Agreement, Privacy Policy or other terms and conditions published at
        www.Mondiad.com and that it will not have any right or remedy rising out of any representation, warranty or
        other statements not expressly set out in this Agreement.
      </Paragraph>
      <Paragraph>
        <b>12.8.</b> All claims related to the use of the Service or Program shall be submitted by the Publisher within
        30 days from the end of the Reporting Period only. In the case of missing the specified term, Mondiad network
        reserves the right not to process the complaint, and all the services shall be deemed rendered properly.
      </Paragraph>
      <Paragraph>
        <b>12.9.</b> Headings to sections and subsections in this Agreement are for the convenience of the parties only
        and are not intended to be a part of or affect the meaning or interpretation hereof.
      </Paragraph>
      <Paragraph>
        <b>12.10.</b> You agree on using any communication method (email message/SMS message/phone) with contact details
        provided in your personal account.
      </Paragraph>

      <Title level={2}>13. Data processing agreement</Title>
      <Paragraph>
        This DPA (Data Processing Agreement) is between User and <>{TERMS_COMPANY_NAME}</>, as defined under the ToU
        (Terms of Use), and forms an integral part of Terms of Use available at https://members.mondiad.com/terms.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> and Users will be referred to as the “Parties”. In the event of any conflict between
        this DPA and the Terms of Use, the terms of this DPA will prevail.
      </Paragraph>
      <Paragraph>
        This Agreement applies only to the extent the EU Data Protection Law applies to the Processing of Personal Data
        under this Agreement, including if (1) the Processing is carried out in the context of the activities of an
        establishment of either Party in the European Economic Area (EEA), or/and (2) the Personal Data is related to
        Data Subjects who are in the EEA and the Processing is related to the offering of goods or services to them or
        the monitoring of their behavior in the EEA.
      </Paragraph>

      <Title level={4}>13.1 Definitions</Title>
      <Paragraph>
        “(Data) Controller” is the entity which determines the means and purposes of the Processing of Personal Data.
      </Paragraph>
      <Paragraph>
        “(Data) Processor” is that entity which Processes Personal Data on the Data Controller’s behalf.
      </Paragraph>
      <Paragraph>
        “Data Subject” is the individual to who’s Personal Data is related to, End Users being included.
      </Paragraph>
      <Paragraph>“End User” is represented the end user of an internet connected device.</Paragraph>
      <Paragraph>
        “GDPR” is the Regulation (EU) 2016/679 of the EU Parliament and the Council of April 27th 2016 on the protection
        of natural persons with regards to the processing of personal data and on the free movement of that, and
        repealing Directive 95/46/EC (a.k.a. “General Data Protection Regulation”).
      </Paragraph>
      <Paragraph>
        “Personal Data” represents any information related to an identified or identifiable individual, as defined in
        Article 4.1 of the GDPR.
      </Paragraph>
      <Paragraph>
        “Processing” includes any operation or set of operations performed upon Personal Data, whether or not by
        automatic means.
      </Paragraph>
      <Paragraph>“Sub-Processor” is any Data Processor engaged by the Processor.</Paragraph>
      <Paragraph>
        “Services” are services provided by <>{TERMS_COMPANY_NAME}</> complying to the general legal terms above
        mentioned.
      </Paragraph>

      <Title level={4}>13.2 Processing Of Personal Data</Title>
      <Paragraph>
        In this DPA and with respect to Personal Data, Client/User/User is Data Controller or Processor and{' '}
        <>{TERMS_COMPANY_NAME}</> is engaged by the Client/User as Processor or Sub-Processor in respect to Personal
        Data, as applicable. The terms of this Agreement will be applied to either of the relations between the Parties
        regarding the Processing of Personal Data mentioned herein.
      </Paragraph>
      <Paragraph>
        Within the purpose of this DPA, Client/User engages <>{TERMS_COMPANY_NAME}</> to collect, process and/or use
        Personal Data on Client/User’s behalf.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> will only Process Personal Data on your behalf, according to your instructions, which
        are the following: (1) Processing shall be carried out according to this DPA, the Terms of Use and pursuant to
        the features and limitations of the applicable Services which <>{TERMS_COMPANY_NAME}</> provides to Client/User;
        and (2) Processing shall be carried out in compliance with other reasonable instructions provided by the
        Client/User, where such instructions are consistent with the Terms of Use. <>{TERMS_COMPANY_NAME}</> will be
        under no obligation to comply with instructions that <>{TERMS_COMPANY_NAME}</> deems as violating applicable
        laws. Processing outside the scope of this DPA (if any) will require: (1) prior written agreement between
        Client/User and <>{TERMS_COMPANY_NAME}</>, and (2) Client/User’s additional instructions for processing.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> uses the Personal Data only to provide the Services in accordance with Terms &
        Conditions, i.e. in order to perform tracking services / serve End Users with interest–based advertising, as
        well as to measure the effectiveness of advertising campaigns and provide you with advertising reports. In that
        context, <>{TERMS_COMPANY_NAME}</> – on your demand – may also combine Personal Data from different sources in
        order to improve Services and integrate Services with external platforms, all of which will be conducted on your
        behalf. <>{TERMS_COMPANY_NAME}</> also processes Personal Data on your behalf and to serve your interests for
        the purposes of fraud prevention, bot detection, rating, analytics, viewability, ad security services.{' '}
        <>{TERMS_COMPANY_NAME}</> may also process data based on the extracts of Personal Data in aggregated and
        non-identifiable forms, including for the purposes of testing, development, control and operation of the
        Services.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> may process the following information on your behalf: IP addresses, language
        information, session-based browsing behavior, header information, End User’s device-related data (such as the
        type or model of the device), operating system, wireless carrier providing communication services to such
        device, geographical location (geo-location) of the device, cookies, advertising identifiers of the device, as
        well as other information we may receive from you or from third parties engaged by <>{TERMS_COMPANY_NAME}</> on
        your behalf, such as non-precise device location based on the IP address, device specifications and user’s
        interest’s information. Client/User also authorizes <>{TERMS_COMPANY_NAME}</> to store and use cookies or pixel
        tags on End User’s device on behalf of the Client/User in order to perform Services. Additional information
        regarding the types of End User’s data that may be collected or used by the Client/User through Services are
        specified in End User Privacy Policy.
      </Paragraph>
      <Paragraph>
        Without derogating from any of the obligations of the Client/User hereunder, the Client/User shall not provide{' '}
        <>{TERMS_COMPANY_NAME}</> with any data 1) which by itself identifies an individual, such as name, address,
        phone number, email address; and 2) regarding children, or any special categories of personal data, as defined
        under Article 9 of the GDPR, except as may otherwise be expressly agreed in writing between the Parties and in
        accordance with the applicable law. This type of data is not necessary to use <>{TERMS_COMPANY_NAME}</>’s
        Services.
      </Paragraph>
      <Paragraph>
        Client/User is responsible for ensuring their own compliance with various laws and regulations, including the
        GDPR. To the extent required under the applicable law, you shall provide an appropriate notice to Data Subjects
        about the Processing of their Personal Data in connection with the use of Services under this DPA and under the
        End User Privacy Policy ({PrivacyLink}), and you shall receive and document the Data Subjects’ consent thereof
        to the extent required under the applicable law.
      </Paragraph>
      <Paragraph>
        To the extent required under the applicable law, Client/User must also use commercially reasonable efforts to
        ensure that the End User is provided with clear and comprehensive information about cookies or other information
        on the End User’s device in connection with the use of Services by the Client/User and, if applicable, consents
        to their storing and accessing. To the extent required under the applicable law, Client/User shall inform the
        End User about third party cookies (or other tracking technologies) which may be placed on Client/User’s
        site(s), specifying the purpose of these cookies (e.g., targeted advertising) and the type of data collected on
        the Client/User’s site(s). Client/User shall also inform End Users of options to deactivate{' '}
        <>{TERMS_COMPANY_NAME}</>’s cookies by including in its privacy policy a link to <>{TERMS_COMPANY_NAME}</>’s End
        User Privacy Policy ({PrivacyLink}), and when legally compulsory, appropriate notice, consent and choice
        mechanisms that comply with relevant laws and regulations, including GDPR.
      </Paragraph>
      <Paragraph>
        You acknowledge and agree that you retain sole responsibility for the lawfulness of the Processing and warrant
        to <>{TERMS_COMPANY_NAME}</> that you are legally allowed to engage <>{TERMS_COMPANY_NAME}</> to process
        Personal Data on your behalf, have provided all necessary notices and obtained all required consents from the
        Data Subjects (if apply) for the purposes of the Processing described in this DPA.
      </Paragraph>

      <Title level={4}>13.3 Rights Of Data Subjects</Title>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> shall notify User via e-mail if when receiving a request from a Data Subject in the
        subject of access to, correction, amendment, deletion of or objection to the processing of that Data Subject’s
        Personal Data. <>{TERMS_COMPANY_NAME}</> shall not respond to any such Data Subject request without User’s prior
        written consent, except in order to confirm that the request relates to the User.
      </Paragraph>
      <Paragraph>
        To the extent that User responds to any such Data Subject request, <>{TERMS_COMPANY_NAME}</> shall provide User,
        to the extent required by law, with commercially reasonable cooperation and assistance in relation to handling
        of a Data Subject’s request, to the extent legally permitted.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> reserves the right to charge additional fees in relation to the cooperation with the
        User in regard to this DPA.
      </Paragraph>

      <Title level={4}>
        13.4 <>{TERMS_COMPANY_NAME}</>’s staff
      </Title>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> shall ensure that its personnel engaged in the Processing of Personal Data is informed
        of the confidential nature of the Personal Data, has received appropriate training on their responsibilities and
        is subject to obligations of confidentiality. Such obligations shall survive the termination of that
        individual’s engagement with <>{TERMS_COMPANY_NAME}</>.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> shall ensure that access to Personal Data is limited only to those members of
        personnel who require that access in order to fulfil <>{TERMS_COMPANY_NAME}</>’s obligations under the Terms of
        Use.
      </Paragraph>

      <Title level={4}>13.5 Security</Title>
      <Paragraph>
        Pursuant to Article 28, Section 3(c) of the General Data Protection Regulation, <>{TERMS_COMPANY_NAME}</> shall
        take the measures required by the Article 32 of the GDPR.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> shall provide sufficient guarantees of implementation of the appropriate technical and
        organizational measures in a manner that the processing will meet the requirements of the GDPR and ensure the
        protection of the rights of the Data Subject.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> imposes appropriate contractual obligations upon its personnel that engages in the
        Processing of Personal Data, including relevant obligations regarding confidentiality, data protection and data
        security. <>{TERMS_COMPANY_NAME}</> ensures that its applicable personnel have been properly informed of the
        confidential nature of the Personal Data, has received appropriate training and has executed written
        confidentiality agreements. <>{TERMS_COMPANY_NAME}</> will further ensure that such confidentiality agreements
        will survive the termination of employment or another form of engagement of its personnel.
      </Paragraph>

      <Title level={4}>13.6 Security Breach Management And Notification</Title>
      <Paragraph>
        If <>{TERMS_COMPANY_NAME}</> becomes aware of any accidental or unlawful destruction, loss, alteration,
        unauthorized disclosure of or access to any Personal Data transmitted, stored, or otherwise Processed on{' '}
        <>{TERMS_COMPANY_NAME}</>’s equipment or in <>{TERMS_COMPANY_NAME}</>’s facilities (“Security Breach”),{' '}
        <>{TERMS_COMPANY_NAME}</> will promptly: (1) notify the User of the Security Breach; (2) investigate the
        Security Breach and provide User with all relevant information about the Security Breach; and (3) take all
        commercially reasonable steps to mitigate the effects and minimize any damage resulting from the Security
        Breach.
      </Paragraph>

      <Title level={4}>13.7 Subprocessing And Transborder Data Transfers</Title>
      <Paragraph>
        User authorizes <>{TERMS_COMPANY_NAME}</> to appoint Sub-Processors in order to provide the Services.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> may continue to use the Sub-Processors already engaged by <>{TERMS_COMPANY_NAME}</>{' '}
        according to this DPA.
      </Paragraph>
      <Paragraph>
        <>{TERMS_COMPANY_NAME}</> may integrate the User’s services with external service providers’ platforms for the
        purpose of providing its Services, on User’s behalf and for the purposes of serving the User’s interests, where
        such external service providers may be Sub-Processors, which User hereby agrees to. A full list of such
        Sub-Processors is available upon the User’s written request directed to <>{TERMS_COMPANY_NAME}</>.
      </Paragraph>
      <Paragraph>
        Although the provisions above, User hereby authorize <>{TERMS_COMPANY_NAME}</> to subcontract the Processing to
        the Sub-Processors based outside of the European Economic Area (EEA) to the extent necessary to duly perform the
        Service(s), under the condition that the Sub-Processors will provide sufficient guarantees in relation to the
        required level of data protection, e.g. through a Privacy Shield certification according to the EU Commission
        Decision 2016/1250, or a subcontracting agreement based on the standard contractual clauses launched by virtue
        of the EU Commission Decision on standard contractual clauses for the transfer of personal data to processors
        established in third countries under Directive 95/46/EC or GDPR (the “Model Contract Clauses”), or based on
        other applicable transborder data transfer mechanisms.
      </Paragraph>

      <Title level={4}>13.8 Others</Title>
      <Paragraph>
        Term: This Agreement shall become effective as of May 25th, 2018. User authorizes <>{TERMS_COMPANY_NAME}</> to
        retain Personal Data for a period of 120 months from the date of its collection on User’s behalf and for the
        purpose of serving its interests, including for fraud prevention, ad security services, reporting services,
        complaints or chargebacks handling. This data may be deleted from <>{TERMS_COMPANY_NAME}</>’s servers after this
        retention period and/or after the termination of Agreement or earlier, at User’s written request.
      </Paragraph>
      <Paragraph>
        Notices: If User wish to make any inquiries about this Agreement, please contact us at{' '}
        <a target="_blank" href="mailto:contact@mondiad.com" onClick={useEmailClick()} rel="noreferrer">
          contact@mondiad.com
        </a>
      </Paragraph>
      <Paragraph>
        Liability: User shall indemnify and hold <>{TERMS_COMPANY_NAME}</>, its officers, directors, employees,
        contractors, and agents harmless from and against all claims, liabilities, administrative fines, suits,
        judgments, actions, investigations, settlements, penalties, fines, damages and losses, demands, costs, expenses,
        and fees including reasonable attorneys’ fees and expenses, arising out of or in connection with any claims,
        demands, investigations, proceedings, or actions brought by data subjects, legal persons (e.g., corporations and
        organizations), or supervisory authorities under the data protection laws that apply to{' '}
        <>{TERMS_COMPANY_NAME}</> in respect of processing of Personal Data on behalf of User through Services.
      </Paragraph>
      <Paragraph>
        The liability of each party under this Agreement shall be subject to the exclusions and limitations of liability
        set out in the legal terms.
      </Paragraph>
      <Paragraph>
        Governing law: This Agreement shall be governed by, and is construed in accordance with, the laws of Gibraltar,
        without giving any effect to any choice of law and provisions thereof that would cause the application of the
        laws of any other jurisdiction.
      </Paragraph>

      <Title level={4}>
        13.9 List of <>{TERMS_COMPANY_NAME}</>’s Sub-Processors
      </Title>
      <Paragraph>Available upon request.</Paragraph>
    </Typography>
  );
};
