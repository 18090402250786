const localStorageRTKey = 'authApp:RT';

function saveRefreshToken(refreshToken: string) {
  localStorage.setItem(localStorageRTKey, refreshToken);
}

function clearRefreshToken() {
  localStorage.removeItem(localStorageRTKey);
}

function getRefreshToken() {
  return localStorage.getItem(localStorageRTKey);
}

export const storage = {
  saveRefreshToken,
  getRefreshToken,
  clearRefreshToken,
};
